import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSchedule(ctx, queryParams) {
      console.log(queryParams)
      const { limit } = queryParams
      const { page } = queryParams
      const { q } = queryParams

      const uri = `/radio/admin/broadcast?page=${page}&limit=${limit}&q=${q}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    saveSchedule(ctx, { item }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/radio/admin/broadcast', {
            items: item,
          })
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
  },
}
